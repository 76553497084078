import { NestleLogo } from '@components/home/icons'
import { Image } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'
import { projects as RicoreProjects } from '@components/ricore/data/projects'
import { projects as PurinaProjects } from '@components/purina/data/projects'
import { projects as NestleProjects } from '@components/nestle/data/projects'
import React from 'react'

const nestleLogo = <NestleLogo color="inherit" w={'inherit'} h={'15px'} />
const ricoreLogo = <Image src={s3ImageURL('ricore-logo.png')} />
const purinaLogo = <Image src={s3ImageURL('purina-logo-friskies.png')} />

export const Agris = [
  {
    logo: nestleLogo,
    projectTitle: NestleProjects[0].title2,
    slug: NestleProjects[0].slug,
    fullname: NestleProjects[0].username,
    location: NestleProjects[0].place,
    description: NestleProjects[0].description,
    image: NestleProjects[0].image,
    order: 0,
  },
  {
    logo: ricoreLogo,
    projectTitle: RicoreProjects[0].title,
    slug: RicoreProjects[0].slug,
    fullname: RicoreProjects[0].fullname,
    location: RicoreProjects[0].place,
    description: RicoreProjects[0].text,
    image: RicoreProjects[0].image,
    order: 1,
  },
  {
    logo: purinaLogo,
    projectTitle: PurinaProjects[0].title,
    slug: PurinaProjects[0].slug,
    fullname: PurinaProjects[0].fullname,
    location: PurinaProjects[0].place,
    description: PurinaProjects[0].text,
    image: PurinaProjects[0].image,
    order: 2,
  },

  {
    logo: nestleLogo,
    projectTitle: NestleProjects[1].title2,
    slug: NestleProjects[1].slug,
    fullname: NestleProjects[1].username,
    location: NestleProjects[1].place,
    description: NestleProjects[1].description,
    image: NestleProjects[1].image,
  },
  {
    logo: ricoreLogo,
    projectTitle: RicoreProjects[1].title,
    slug: RicoreProjects[1].slug,
    fullname: RicoreProjects[1].fullname,
    location: RicoreProjects[1].place,
    description: RicoreProjects[1].text,
    image: RicoreProjects[1].image,
  },
  {
    logo: ricoreLogo,
    projectTitle: RicoreProjects[2].title,
    slug: RicoreProjects[2].slug,
    fullname: RicoreProjects[2].fullname,
    location: RicoreProjects[2].place,
    description: RicoreProjects[2].text,
    image: RicoreProjects[2].image,
  },
  {
    logo: purinaLogo,
    projectTitle: PurinaProjects[1].title,
    slug: PurinaProjects[1].slug,
    fullname: PurinaProjects[1].fullname,
    location: PurinaProjects[1].place,
    description: PurinaProjects[1].text,
    image: PurinaProjects[1].image,
  },
  {
    logo: purinaLogo,
    projectTitle: PurinaProjects[2].title,
    slug: PurinaProjects[2].slug,
    fullname: PurinaProjects[2].fullname,
    location: PurinaProjects[2].place,
    description: PurinaProjects[2].text,
    image: PurinaProjects[2].image,
  },
]
