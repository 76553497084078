import { Heading, Flex, Box, Text, Button, useBreakpointValue } from '@chakra-ui/react'
import { Carousel, Icon } from '@miimosa/design-system'
import React from 'react'
import { Agris } from '@components/common/data/agris'
import { s3ImageURL } from '@miimosa/common'
import { Agri } from '@lib'

export const RenderAgriImage = ({
  projectTitle,
  logo,
  image,
}: {
  projectTitle: string
  logo: React.ReactNode
  image: string
}) => {
  return (
    <Flex
      direction="column"
      textAlign="start"
      pos="relative"
      w={'full'}
      bgImage={s3ImageURL(image)}
      bgPosition="center"
      bgSize="cover"
      h="300px"
    >
      <Box
        pos="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%)"
        zIndex="1"
      />
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        bottom="0"
        zIndex={2}
        w={'full'}
        p={{ base: 1, md: 2 }}
      >
        <Text fontSize={14} mt={2} fontWeight={600} color="white">
          {projectTitle}
        </Text>
        <Box w="40px" color="white">
          {logo}
        </Box>
      </Flex>
    </Flex>
  )
}

export const RenderAgri = ({ agri, onClick }: { agri: Agri; onClick: any }) => {
  return (
    <Flex
      direction="column"
      border="solid 1px"
      borderRadius="2px"
      borderColor="brand_brown"
      alignItems="center"
      minW={'200px'}
      justifyContent="space-between"
      w={'full'}
    >
      <RenderAgriImage logo={agri.logo} projectTitle={agri.projectTitle} image={agri.image} />
      <Flex direction="column" justifyContent="space-between" w={'full'} p={2} textColor="brand_dark_brown" rowGap={1}>
        <Flex dir="row" alignItems="center" columnGap={2}>
          <Flex
            borderRadius="full"
            backgroundColor="brand_dark_brown"
            alignItems="center"
            justifyContent="center"
            p={1.5}
          >
            <Icon name="User" zIndex={2} color="white" h="auto" w="10px" />
          </Flex>
          <Text fontSize={14} fontWeight={600}>
            {agri.fullname}
          </Text>
        </Flex>
        <Flex dir="row" alignItems="center" columnGap={2}>
          <Flex
            p={1.5}
            borderRadius="full"
            backgroundColor="brand_dark_brown"
            alignItems="center"
            justifyContent="center"
          >
            <Icon name="PinMap" zIndex={2} color="white" h="auto" w="10px" verticalAlign="middle" />
          </Flex>
          <Text fontSize={14} fontWeight={600}>
            {agri.location}
          </Text>
        </Flex>
      </Flex>

      <Button
        w={'90%'}
        py={4}
        my={4}
        backgroundColor={'brand_brown'}
        borderRadius="0"
        color={'white'}
        variant="brandSecondary"
        as={'a'}
        href={agri.slug ? '/agriculteurs?slug=' + agri.slug + '#' : '/agriculteurs#'}
        _focus={{ boxShadow: 'none' }}
        _hover={{ bg: 'brand_light_brown' }}
        onClick={onClick}
      >
        Découvrir son projet
      </Button>
    </Flex>
  )
}

export const LesAgriculteursPartenaires = () => {
  const itemsPerPage = useBreakpointValue({ base: 1, sm: 1, md: 2, lg: 2, xl: 3, '2xl': 5 }, { fallback: 'lg' })
  return (
    <Flex position="relative" alignItems="center" justifyContent="center" p={8} w={'full'} mb={12}>
      <Flex direction="column" w={'full'} alignItems="center" justifyContent="center">
        <Box as="span" id="agriculteurs" visibility="hidden" height="0" transform="translateY(-80px)" />
        <Heading textAlign="center" mb={8} w={'full'}>
          Les agriculteurs partenaires
        </Heading>

        <Carousel
          w={'99vw'}
          mt={8}
          isDark={false}
          draggable
          boxedControls
          SlideComponent={(agri: Agri, index: number) => (
            <Flex maxW="90%" key={index} m={'auto'}>
              <RenderAgri agri={agri} onClick={() => undefined} />
            </Flex>
          )}
          centerMode={true}
          data={Agris}
          centerPadding="40px"
          navColor="gray"
          navBottom
          itemsPerPage={itemsPerPage}
        />

        <Button my={4} color={'white'} as="a" href={'/agriculteurs'} variant="brandPrimary">
          Découvrir tous les agriculteurs
        </Button>
      </Flex>
    </Flex>
  )
}
