import React from 'react'
import { Box, Heading, Flex, Text, Button, useBreakpointValue } from '@chakra-ui/react'
import { Carousel } from '@miimosa/design-system'
import { s3ImageURL } from '@miimosa/common'
import { StaticProject, Operation } from '@lib'
import { operations } from '@components/home/data/operations'

const ProjectItem = ({
  image,
  title,
  state,
  operationSlug,
  anchor,
}: {
  image: string
  title: string
  state: string
  operationSlug: string
  anchor: string
}) => (
  <Flex
    direction="column"
    textAlign="start"
    w={'full'}
    h={{ base: 'auto', md: '300px', lg: 'full' }}
    justifyContent="flex-start"
    alignItems="flex-start"
    px={{ base: 2, md: 0 }}
  >
    <Flex h={{ base: '250px', md: '70%' }} w={'full'} bgImage={s3ImageURL(image)} bgSize="cover" bgPos="center" />

    <Flex direction="column" p={{ base: 2, md: 0 }}>
      <Text fontSize={14} fontWeight={400} mt={2}>
        {title}
      </Text>

      {state != 'À venir' && (
        <Button
          mt={2}
          border="solid 1px orange"
          backgroundColor="transparent"
          fontSize={12}
          px={2}
          py={1}
          color="orange"
          as="a"
          href={operationSlug + '#' + anchor}
          variant={'brandSecondary'}
        >
          {state === 'En cours' ? 'Je vote pour ce projet' : 'Je découvre ce projet'}
        </Button>
      )}
    </Flex>
  </Flex>
)

const ProjectsGrid = ({
  projects,
  state,
  operationSlug,
  isMobile,
}: {
  projects: StaticProject[]
  state: string
  operationSlug: string
  isMobile: boolean | undefined
}) => {
  if (isMobile) {
    return (
      <Flex w="full" mb={8}>
        <Carousel
          showControls={false}
          draggable
          SlideComponent={(project) => (
            <ProjectItem
              key={project.slug}
              image={project.image}
              title={project.title}
              state={state}
              operationSlug={operationSlug}
              anchor={project.anchor}
            />
          )}
          centerMode={true}
          data={projects}
          itemsPerPage={1}
          hasDots={true}
          withArrow={false}
        />
      </Flex>
    )
  } else {
    return (
      <Flex justifyContent="center" alignItems="center" gap={4} mb={4} h={'400px'} w={'90%'}>
        {projects.map((project, index) => (
          <ProjectItem
            key={index}
            image={project.image}
            title={project.title}
            state={state}
            operationSlug={operationSlug}
            anchor={project.anchor}
          />
        ))}
      </Flex>
    )
  }
}

const OperationState = ({ state }: { state: string }) => {
  return (
    <Flex
      fontSize={12}
      fontWeight={500}
      backgroundColor="brand_dark_brown"
      color="white"
      px={4}
      py={1}
      borderRadius="2px"
      alignItems="center"
      justifyContent="center"
      whiteSpace="nowrap"
    >
      <Box
        width="8px"
        height="8px"
        borderRadius="full"
        backgroundColor={state === 'Terminé' ? 'red.500' : state === 'Prochainement' ? 'yellow.500' : 'green.500'}
        mr={2}
      />
      {state}
    </Flex>
  )
}

const OperationComponent = ({
  operation,
  index,
  isMobile,
}: {
  operation: Operation
  index: number
  isMobile: boolean | undefined
}) => {
  const { slug, message, state, color, logo, projects } = operation

  return (
    <Flex justifyContent="center" mb={8}>
      <Flex
        key={index}
        position="relative"
        direction="column"
        maxWidth={{ base: '100%', md: '1300px' }}
        px={{ base: '', md: '30px' }}
        minW={{ base: '0', md: '400px' }}
        textAlign="center"
        w={'full'}
      >
        <Flex
          direction="column"
          border="solid 2px"
          borderColor={color}
          borderRadius="2px"
          alignItems="center"
          p={{ base: 0, md: 4 }}
          backgroundColor="#FFFDF5"
        >
          {isMobile ? (
            <Flex justifyContent="center" alignItems="center" m={8} columnGap={8}>
              {logo}
            </Flex>
          ) : (
            <Flex my={8}>
              <Flex position="absolute" top="5%" right="10%">
                <OperationState state={state} />
              </Flex>

              <Flex
                alignItems={{ base: 'start', md: 'center' }}
                justifyContent={{ base: 'start', md: 'center' }}
                w="full"
                h="150px"
                m={4}
              >
                {logo}
              </Flex>
            </Flex>
          )}

          <ProjectsGrid projects={projects} state={state} isMobile={isMobile} operationSlug={operation.slug} />
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            backgroundColor="#F9F1E2"
            my={{ base: 0, md: 2 }}
            h="auto"
            minH="150px"
            w={'full'}
          >
            <Flex h={'full'} w={'full'} alignItems={'center'} justifyContent={'center'} px={2}>
              <Text size="md" color="black" fontSize={14} fontWeight={700}>
                {message}
              </Text>
            </Flex>

            <Button
              backgroundColor={state != 'À venir' ? color : 'gray'}
              color="white"
              variant={'brandSecondary'}
              mb={4}
              as={state != 'À venir' ? 'a' : 'span'}
              {...(state != 'À venir' ? { href: slug } : {})}
              opacity={state != 'À venir' ? 1 : 0.5}
            >
              {state != 'À venir' ? 'Découvrir l’opération' : state}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const DecouvrezNosOperations = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  const operationsEnCours = operations.filter((operation) => operation.state === 'En cours')

  return (
    <Flex position="relative" alignItems="center" justifyContent="center" p={4} pt={12} bgColor="brand_gray" w={'full'}>
      <Flex direction="column" w={'full'}>
        <Box as="span" id="operations" visibility="hidden" height="0" transform="translateY(-80px)" />
        <Heading textAlign="center" mb={8} w={'full'}>
          {isMobile ? <>L’opération en cours</> : <>Découvrez nos opérations</>}
        </Heading>
        <Box as="span" id="projects" visibility="hidden" height="0" transform="translateY(-80px)" />
        {isMobile ? (
          <Flex direction="column" rowGap={8}>
            {operationsEnCours.map((operation, index) => (
              <OperationComponent key={index} operation={operation} index={index} isMobile={isMobile} />
            ))}
          </Flex>
        ) : (
          <Carousel
            w={'98vw'}
            mt={8}
            isDark={false}
            draggable
            boxedControls
            centerMode={true}
            SlideComponent={(operation, index) => (
              <OperationComponent key={index} operation={operation} index={index} isMobile={isMobile} />
            )}
            itemsPerPage={1}
            centerPadding="15%"
            hasDots={true}
            data={operations}
            navColor="gray"
            navBottom
          />
        )}
      </Flex>
    </Flex>
  )
}
