import {
  Flex,
  SimpleGrid,
  Heading,
  Collapse,
  Box,
  Image,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Icon } from '@miimosa/design-system'
import React, { useEffect } from 'react'
import { RenderAgri } from '@components/home/LesAgriculteursPartenaires'
import { Agris } from '@components/common/data/agris'
import { s3ImageURL } from '@miimosa/common'
import { useRouter } from 'next/router'
import { Agri } from '@lib'

export const RenderAccordionAgri = (agri: Agri, index: number) => {
  return (
    <AccordionItem
      border="solid 1px"
      borderRadius="2px"
      borderColor="brand_dark_brown"
      alignItems="center"
      justifyContent="center"
      m={4}
      key={index}
      maxW="800px"
    >
      {({ isExpanded }) => (
        <>
          <Flex direction="column" textAlign="start" w="full" pos="relative" scrollMarginTop="75px">
            <Box as="span" id={agri.slug} visibility="hidden" height="0" transform="translateY(-200px)" />
            <Image src={s3ImageURL(agri.image)} objectFit="cover" />
            <Box
              pos="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              bg="linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%)"
              zIndex="1"
            />
            <Flex
              zIndex={3}
              justifyContent="space-between"
              position="absolute"
              w="full"
              bottom="0"
              p={2}
              alignItems="center"
              columnGap={4}
            >
              <Text fontSize={14} mt={2} fontWeight={600} color="white">
                {agri.projectTitle}
              </Text>
              <Box mr={1} w="60px" color="white">
                {agri.logo}
              </Box>
            </Flex>
          </Flex>

          <Flex
            direction={{ base: 'column', xl: 'row' }}
            justifyContent="space-between"
            w={'full'}
            p={{ base: 2, xl: 4 }}
            textColor="brand_dark_brown"
            m={2}
            rowGap={2}
          >
            <Flex dir="row" alignItems="center" columnGap={1}>
              <Flex
                borderRadius="full"
                backgroundColor="brand_dark_brown"
                alignItems="center"
                justifyContent="center"
                p={1}
              >
                <Icon name="User" zIndex={2} color="white" h="auto" w="10px" />
              </Flex>
              <Text fontSize={14} fontWeight={600}>
                {agri.fullname}
              </Text>
            </Flex>
            <Flex dir="row" alignItems="center" columnGap={1}>
              <Flex
                p={1}
                borderRadius="full"
                backgroundColor="brand_dark_brown"
                alignItems="center"
                justifyContent="center"
              >
                <Icon name="PinMap" zIndex={2} color="white" h="auto" w="8px" verticalAlign="middle" />
              </Flex>
              <Text fontSize={14} fontWeight={600}>
                {agri.location}
              </Text>
            </Flex>
          </Flex>

          <Flex justifyContent="center" w={'full'} my={4}>
            <AccordionButton
              py={4}
              w={'80%'}
              backgroundColor={'brand_brown'}
              borderRadius="0"
              color={'white'}
              justifyContent="center"
              _focus={{ boxShadow: 'none' }} // Retirer l'effet de focus par défaut
              _hover={{ bg: isExpanded ? 'brand_light_brown' : 'brand_brown' }}
            >
              Découvrir son projet
            </AccordionButton>
          </Flex>

          <AccordionPanel pb={4}>
            <Text fontWeight={600} mb={2}>
              Description du projet:
            </Text>
            <Text dangerouslySetInnerHTML={{ __html: agri.description }} />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}

export const MurDesAgriculteurs = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  const [showCollapsedSection, setShowCollapsedSection] = React.useState(false)
  const [index, setIndex] = React.useState(0)

  const handleToggle = (i: number) => {
    setIndex(i)
    setShowCollapsedSection(true)
  }

  const { logo, projectTitle, fullname, location, description, image } =
    index !== -1
      ? Agris[index]
      : {
          logo: '',
          projectTitle: '',
          fullname: '',
          location: '',
          description: '',
          image: '',
        }
  const router = useRouter()
  const { slug } = router.query

  useEffect(() => {
    if (slug) {
      // Trouver l'index correspondant au slug
      const queryStringIndex = Agris.findIndex((agri) => agri.slug === slug)

      // Mettre à jour l'état avec l'index correspondant
      if (queryStringIndex !== -1) {
        setIndex(queryStringIndex)
        setShowCollapsedSection(true)
      }

      // Supprimer le slug de l'URL
      if (!isMobile) {
        const { pathname, query } = router
        delete query.slug
        router.replace({ pathname, query }, undefined, { shallow: true })
      } else {
        const { pathname, query } = router
        delete query.slug

        const newUrl = {
          pathname,
          query,
          hash: `#${slug}`,
        }

        router.replace(newUrl, undefined, { shallow: true })
      }
    }
  }, [slug, isMobile, index])

  const handleAccordionChange = React.useCallback((expandedIndex: number) => {
    setIndex(expandedIndex)
  }, [])

  return isMobile ? (
    <Flex bgColor="white" w="fit-content" justifyContent="center" direction="column" alignItems="center">
      <Heading textAlign="center" my={8} w={'100vw'} id="top">
        Les agriculteurs partenaires
      </Heading>

      <Accordion w="full" allowToggle={true} index={index} onChange={handleAccordionChange}>
        {Agris.map((agri, i) => RenderAccordionAgri(agri, i))}
      </Accordion>
    </Flex>
  ) : (
    <Flex bgColor="white" w="fit-content" justifyContent="center" direction="column" alignItems="center">
      <Heading textAlign="center" my={16} w={'100vw'} id="top">
        Les agriculteurs partenaires
      </Heading>

      <Flex w="60%" direction="column">
        <Collapse defaultChecked={false} in={showCollapsedSection} animateOpacity>
          <Flex
            direction="row"
            textAlign="center"
            mb={8}
            w={'full'}
            border="solid 1px"
            borderColor="brand_brown"
            borderRadius="2px"
          >
            <Flex direction="column" borderRight="solid 1px" borderColor="brand_brown" alignItems="center" maxW="400px">
              <Flex
                direction="column"
                textAlign="start"
                w="full"
                minW="400px"
                bgImage={s3ImageURL(image)}
                bgSize="cover"
                h="300px"
              />

              <Flex
                direction="column"
                justifyContent="space-between"
                w={'full'}
                p={{ base: 2, xl: 4 }}
                textColor="brand_dark_brown"
                rowGap={2}
              >
                <Flex dir="row" alignItems="center" columnGap={1}>
                  <Flex
                    borderRadius="full"
                    backgroundColor="brand_dark_brown"
                    alignItems="center"
                    justifyContent="center"
                    p={1.5}
                  >
                    <Icon name="User" zIndex={2} color="white" h="auto" w="10px" />
                  </Flex>
                  <Text fontSize={14} fontWeight={600}>
                    {fullname}
                  </Text>
                </Flex>
                <Flex dir="row" alignItems="center" columnGap={1}>
                  <Flex
                    p={1.5}
                    borderRadius="full"
                    backgroundColor="brand_dark_brown"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon name="PinMap" zIndex={2} color="white" h="auto" w="10px" verticalAlign="middle" />
                  </Flex>
                  <Text fontSize={14} fontWeight={600}>
                    {location}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex direction="column" textAlign="left" p={8} w={'full'}>
              <Flex direction="row" justifyContent="space-between">
                <Heading mb={6}>{projectTitle}</Heading>
                <Box mr={1} w="120px" h={'auto'} color="#73A277">
                  {logo}
                </Box>
              </Flex>
              <Text fontWeight={600} mb={2}>
                Description du projet:
              </Text>
              <Text dangerouslySetInnerHTML={{ __html: description }} />
            </Flex>
          </Flex>
        </Collapse>

        <SimpleGrid templateColumns="repeat(3, 1fr)" gap={8} mb={16}>
          {Agris.map((agri, i) => {
            const agriWithoutSlug = {
              description: agri.description,
              logo: agri.logo,
              fullname: agri.fullname,
              location: agri.location,
              projectTitle: agri.projectTitle,
              image: agri.image,
            }
            return (
              <Box key={i}>
                <RenderAgri agri={agriWithoutSlug} onClick={() => handleToggle(i)} />
              </Box>
            )
          })}
        </SimpleGrid>
      </Flex>
    </Flex>
  )
}
